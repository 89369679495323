<template>

    <Head :title="$t('credit-payment.all_credits')" />

    <AuthenticatedLayout>
        <template #header>
            <div class="flex items-center justify-between">
                <h1 class="text-2xl font-semibold text-gray-900">
                    {{ $t('credit-payment.all_credits') }}
                </h1>
                <div>
                    <div class="flex rounded-md shadow-sm">
                        <div class="relative flex flex-grow items-stretch focus-within:z-10">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none"
                                    viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                            <input id="search" v-model="searchQuery" type="text" name="search"
                                class="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="" @keyup="search" />
                        </div>
                        <button type="button"
                            class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                            @click="search">{{ $t('credit-payment.search') }}</button>
                    </div>
                </div>
            </div>
        </template>

        <pagination class="mb-2" :links="creditPayments" />

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-4 lg:px-8">
                    <div class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.order-date') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.order_id') }}</th>

                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.credit-date') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.iban_customer') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.amount') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.reason') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.status') }}</th>
                                    <th scope="col"
                                        class="px-4 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                                        {{ $t('credit-payment.created_by') }}</th>
                                    <th scope="col" class="relative px-4 py-2">
                                        <span class="sr-only">{{ $t('credit-payment.view') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="creditPayment in creditPayments.data" :key="creditPayment.id">
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <div class="text-xs font-medium text-gray-900">
                                            {{ dayjs(creditPayment.order.created_at).format('LL') }}
                                        </div>
                                        <div class="text-xs text-gray-500">
                                            {{ dayjs(creditPayment.order.created_at).format('LT') }}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <Link v-if="can('view order')" :href="route('orders.show', {order: creditPayment.order_id})" class="text-indigo-600 hover:text-indigo-900">
                                            {{ creditPayment.order.number }}<br>
                                        </Link>
                                        <span v-else>
                                            {{ creditPayment.order.number }}<br>
                                        </span>
                                        <Link v-if="can('view customers')" :href="route('customers.show', {customer: creditPayment.order.customer_id})" class="text-indigo-600 hover:text-indigo-900">
                                            {{ creditPayment.order.customer.firstname }} {{ creditPayment.order.customer.lastname }}<br>
                                        </Link>
                                        <span v-else>
                                            {{ creditPayment.order.customer.firstname }} {{ creditPayment.order.customer.lastname }}<br>
                                        </span>
                                        {{ creditPayment.order.customer.email }}
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <div class="text-xs font-medium text-gray-900">
                                            {{ dayjs(creditPayment.created_at).format('LL') }}
                                        </div>
                                        <div class="text-xs text-gray-500">
                                            {{ dayjs(creditPayment.created_at).format('LT') }}
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <strong>{{ creditPayment.counterpart_name }}</strong><br>
                                        {{ creditPayment.iban }}<br>
                                        {{ creditPayment.bic_code }}
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <span v-if="creditPayment.reason_id === 11">
                                            {{ formatPrice(creditPayment.to_much_paid / 100) }}
                                        </span>
                                        <span v-else>
                                            {{ formatPrice(creditPayment.credit_total / 100) }}
                                        </span>

                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">

                                        {{ $t('credit-payment.' + creditPayment.reason_relation.name) }}<br>
                                        <span v-if="creditPayment.reason_relation.name === 'reason_other'">
                                             {{ creditPayment.reason}}
                                        </span>
<!--                                        <InformationCircleIcon class="h-6 w-6 text-blue-600 inline-block"-->
<!--                                                               v-if="creditPayment.reason_relation.name === 'reason_other'"-->
<!--                                                               @click="reasonToShow = creditPayment" />-->
                                    </td>
                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <status-badge :status="creditPayment.status"></status-badge>
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        {{ creditPayment.user_name }}
                                    </td>

                                    <td class="whitespace-nowrap px-4 py-2 text-xs font-medium text-gray-900">
                                        <Link v-if="can('update credit payment') && creditPayment.status === 'new'"
                                            class="block text-indigo-600 hover:text-indigo-900"
                                            :href="route('credit-payment.edit', { order: creditPayment.order.id })">
                                        {{ $t('credit-payment.edit') }}</Link>
                                        <button
                                            v-if="can('delete credit payment') && (creditPayment.status !== 'paid' && creditPayment.status !== 'rejected' && creditPayment.status !== 'approved' && creditPayment.status !== 'golden_ticket')"
                                            class="text-indigo-600 hover:text-indigo-900"
                                            @click="confirmationDelete = creditPayment">{{ $t('credit-payment.delete')
                                            }}</button>
                                        <button v-if="can('update status credit payment') && creditPayment.status === 'new'"
                                            class="block text-right text-indigo-600 hover:text-indigo-900"
                                            @click="updateCreditPaymentStatus = creditPayment; reasonData = creditPayment.reason_data">{{
        $t('credit-payment.edit-state') }}</button>
                                    </td>
                                </tr>
                                <tr v-if="!creditPayments.data.length">
                                    <td colspan="8" class="whitespace-nowrap px-4 py-2 font-medium text-gray-900">{{ $t('credit-payment.notfound') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <pagination class="mt-2" :links="creditPayments" />

        <update-status-modal v-if="can('update status credit payment')" :reasonData="reasonData"
            :creditPayment="updateCreditPaymentStatus" @close="updateCreditPaymentStatus = false" />
        <ConfirmationModal v-if="can('delete credit payment')" :show="confirmationDelete != null"
            @close="confirmationDelete = null">
            <template #title>
                {{ $t('credit-payment.delete_credit') }}
            </template>

            <template #content> {{ $t('credit-payment.areusure') }}</template>

            <template #footer>
                <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    @click="deleteCredit">{{ $t('credit-payment.confirm') }}</button>
                <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    @click="confirmationDelete = null">{{ $t('credit-payment.cancel') }}</button>
            </template>
        </ConfirmationModal>
        <InformationModal :show="reasonToShow !== null" @close="reasonToShow = null">
            <template #title>
                {{ $t('credit-payment.reason') }}
            </template>
            <template #content>
                {{ reasonToShow?.reason }}
            </template>
        </InformationModal>
    </AuthenticatedLayout>
</template>

<script setup>
import AuthenticatedLayout from '@/Layouts/Authenticated.vue'
import { Head, Link, useForm, router } from '@inertiajs/vue3'
import Pagination from '@/Components/Pagination.vue'
import StatusBadge from '@/Components/StatusBadge.vue'
import UpdateStatusModal from "@/Pages/CreditPayments/UpdateStatusModal.vue";
import ConfirmationModal from "@/Components/ConfirmationModal.vue";
import InformationModal from "@/Components/InformationModal.vue";
import { ref } from 'vue';

const props = defineProps({
    creditPayments: Object,
    indexTitle: String,
    oldSearchQuery: String,
    currentType: String
});

const confirmationDelete = ref(null);
const updateCreditPaymentStatus = ref(false);
const reasonData = ref(false);
const searchQuery = ref(props.oldSearchQuery);
const reasonToShow = ref(null);
const form = useForm({});

const deleteCredit = () => {
    if (confirmationDelete.value) {
        form.delete(route('credit-payment.destroy', { credit_payment: confirmationDelete.value.id }))
    }
    confirmationDelete.value = null;
}
const search = () => {
    router.get(route(route().current()),
        { query: searchQuery.value },
        { preserveState: true }
    );
}
</script>
