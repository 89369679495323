<template>
    <Head :title="$t('credit-payment.edit_credit_payment') + ' - ' + order.extern_id" />
    <AuthenticatedLayout>
        <template #header>
            <h1 class="text-2xl font-semibold text-gray-900">{{ $t('credit-payment.edit_credit_payment') }} - {{ order.extern_id }}</h1>
        </template>

        <form
            method="POST"
            class="overflow-hidden bg-white p-6 shadow-sm sm:rounded-lg"
            @submit.prevent="onSubmit"
        >
            <div class="grid grid-cols-6 gap-8">
                <!-- Order Info -->
                <div class="col-span-3">
                    <div class="py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="sm:py-5">
                                <h3 class="text-lg font-semibold">{{ $t('credit-payment.order_info') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.id') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ order.extern_id }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_no') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ order.number }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_subtotal') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ formatPrice(order.order_subtotal / 100) }}
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.order_shipment') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ formatPrice((order.shipping_subtotal + order.shipping_tax) / 100) }}
                                    <small>({{ $t('credit-payment.inc') }} {{ formatPrice(order.shipping_tax / 100) }} {{ $t('credit-payment.vat') }})</small>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.total_amount') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ formatPrice(order.total / 100) }}
                                    <small>({{ $t('credit-payment.inc') }} {{ formatPrice(order.order_tax / 100) }} {{ $t('credit-payment.vat') }})</small>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <!-- Customer Info -->
                <div class="col-span-3">
                    <div class="py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="sm:py-5">
                                <h3 class="text-lg font-semibold">{{ $t('credit-payment.customer_information') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.customer') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    <div v-if="!order.customer">{{ $t('credit-payment.guest') }}</div>
                                    <div v-else class="text-sm font-medium text-gray-900">
                                        <div>
                                            {{ order.customer.firstname }}
                                            {{ order.customer.lastname }}
                                        </div>
                                        <div class="text-sm text-gray-500">
                                            <div>
                                                {{ order.customer.email }}
                                            </div>
                                        </div>
                                    </div>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.invoice_address') }}</dt>
                                <dd class="mt-1 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">
                                        <div v-if="order.billing_company_name" class="font-bold">
                                            {{ order.billing_company_name }}
                                        </div>
                                        <div>
                                            {{ order.billing_firstname }}
                                            {{ order.billing_lastname }}
                                        </div>
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        <div>
                                            {{ order.billing_address }}
                                        </div>
                                        <div>
                                            {{ order.billing_postcode }}
                                            {{ order.billing_city }}
                                            {{ order.billing_country }}
                                        </div>
                                    </div>
                                    <div v-if="order.email" class="mt-2 text-sm font-medium text-gray-900">
                                        {{ order.email }}
                                    </div>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.delivery_address') }}</dt>
                                <dd class="mt-1 sm:mt-0">
                                    <div class="text-sm font-medium text-gray-900">
                                        <div v-if="order.shipping_company_name" class="font-bold">
                                            {{ order.shipping_company_name }}
                                        </div>
                                        <div>
                                            {{ order.shipping_firstname }}
                                            {{ order.shipping_lastname }}
                                        </div>
                                    </div>
                                    <div class="text-sm text-gray-500">
                                        <div>
                                            {{ order.shipping_address }}
                                        </div>
                                        <div>
                                            {{ order.shipping_postcode }}
                                            {{ order.shipping_city }}
                                            {{ order.shipping_country }}
                                        </div>
                                    </div>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>

                <!-- credit information -->
                <div class="col-span-3">
                    <div class="py-5 sm:p-0">
                        <dl class="sm:divide-y sm:divide-gray-200">
                            <div class="sm:py-5">
                                <h3 class="text-lg font-semibold">{{ $t('credit-payment.credit_information') }}</h3>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.created_at') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    <div class="text-xs font-medium text-gray-900">
                                        {{ dayjs(creditPayment.created_at).format('LL') }}
                                    </div>
                                    <div class="text-xs text-gray-500">
                                        {{ dayjs(creditPayment.created_at).format('LT') }}
                                    </div>
                                </dd>
                            </div>
                            <div class="py-4 sm:grid sm:grid-cols-2 sm:py-5">
                                <dt class="text-sm font-medium text-gray-500">{{ $t('credit-payment.created_by') }}</dt>
                                <dd class="mt-1 text-sm text-gray-900 sm:mt-0">
                                    {{ creditPayment.user_name }}
                                </dd>
                            </div>

                        </dl>

                    </div>
                </div>


                <hr class="col-span-6" />


                <div class="col-span-6">
                    <Label for="reason_id">
                        {{ $t('credit-payment.reason')}}
                        <span class="text-red-500">*</span>
                    </Label>
                    <select id="reason_id" v-model="form.reason_id" class="mt-1 block w-full rounded focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" required>
                        <option value="" disabled>{{ $t('credit-payment.select_reason') }}</option>
                        <option v-for="item in reasons" :key="item.id" :value="item.id">
                            {{ $t('credit-payment.' + item.name) }}
                        </option>
                    </select>
                    <ValidationError name="reason_id" />

                    <template v-if="form.reason_id === 5">
                        <label for="reason">
                            {{ $t('credit-payment.reason_other_placeholder') }}
                            <span class="text-red-500">*</span>
                        </label>

                        <Textarea id="reason" v-model="form.reason" class="mt-5 block w-full focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" />

                        <ValidationError name="reason" />
                    </template>
                </div>

                <template v-if="form.reason_id === 11">
                    <div class="col-span-6">
                        <label for="to_much_paid_double_paid_amount">
                            {{ $t('credit-payment.to_much_paid_double_paid_amount') }}
                            <span class="text-red-500">*</span>
                        </label>

                        <MoneyInput id="to_much_paid_double_paid_amount" v-model="form.to_much_paid_double_paid_amount" class="mt-1 w-40 text-right" />

                        <ValidationError name="to_much_paid_double_paid_amount" />
                    </div>
                </template>


                <template v-if="usedBankAccounts.length > 1 && form.reason_id > 0 && ![12].includes(form.reason_id)">
                    <div class="col-span-6">
                        <label for="bankaccount-select" class="block font-medium text-sm text-gray-700">
                            {{ $t('credit-payment.select_bank_account') }}
                        </label>
                        <select
                            id="bankaccount-select"
                            v-model="selectedBankAccount"
                            class="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            @change="applySelectedBankAccount"
                        >
                            <option :value="null" disabled>
                                {{ $t('credit-payment.select_bank_account') }}
                            </option>
                            <option
                                v-for="(account, index) in usedBankAccounts"
                                :key="index"
                                :value="account">
                                {{ account.counterpart_name }} ({{ account.iban }})
                            </option>
                            <option value="other">{{ $t('credit-payment.other') }}</option>
                        </select>

                        <ValidationError name="selectedBankAccount" />
                    </div>
                </template>

                <template v-if="form.reason_id > 0 && ![12].includes(form.reason_id)">
                    <div class="col-span-3">
                        <label for="counterpart_name" class="block font-medium text-sm text-gray-700">
                            {{ $t('credit-payment.counterpart_name') }}
                            <span v-if="form.reason_id > 0 && ![12, 5].includes(form.reason_id)" class="text-red-500">*</span>
                        </label>

                        <input
                            id="counterpart_name"
                            v-model="form.counterpart_name"
                            type="text"
                            class="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="John Doeh"
                            :required="form.reason_id > 0 && ![12, 5].includes(form.reason_id)"
                        />

                        <ValidationError name="counterpart_name" />
                    </div>

                    <div class="col-span-2">
                        <label for="iban" class="block font-medium text-sm text-gray-700">
                            {{ $t('credit-payment.iban_customer') }}
                            <span v-if="form.reason_id > 0 && ![12, 5].includes(form.reason_id)" class="text-red-500">*</span>
                        </label>

                        <input
                            id="iban"
                            v-model="form.iban"
                            type="text"
                            class="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="NL00 KNAB 0000 0000 00"
                            :required="form.reason_id > 0 && ![12, 5].includes(form.reason_id)"
                        />

                        <ValidationError name="iban" />
                    </div>

                    <div class="col-span-1">
                        <label for="bic_code" class="block font-medium text-sm text-gray-700">
                            {{ $t('credit-payment.bic_code') }}
                            <span v-if="form.reason_id > 0 && ![12, 5].includes(form.reason_id)" class="text-red-500">*</span>
                        </label>

                        <input
                            id="bic_code"
                            v-model="form.bic_code"
                            type="text"
                            class="mt-1 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            placeholder="KNABNL2H"
                            :required="form.reason_id > 0 && ![12, 5].includes(form.reason_id)"
                        />

                        <ValidationError name="bic_code" />
                    </div>
                </template>

                <hr class="col-span-6" />

                <template v-if="form.reason_id > 0 && form.reason_id != 11">
                    <!-- Items -->
                    <div class="col-span-6">
                        <h3 class="text-xl font-semibold mb-4">{{ $t('credit-payment.items') }}</h3>

                        <table class="w-full border-collapse">
                            <thead class="border-b">
                            <tr>
                                <th class="text-left py-2 align-middle">{{ $t('credit-payment.item') }}</th>
                                <th class="text-right py-2 align-middle">{{ $t('credit-payment.qty') }}</th>
                                <th class="text-right py-2 align-middle">{{ $t('credit-payment.subtotal') }}</th>
                                <th class="text-right py-2 align-middle">{{ $t('credit-payment.vat') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, iIndex) in localOrder.items" :key="iIndex" class="border-b">
                                <td class="py-2 pr-4 w-[500px] align-middle">
                                    {{ item.name }}
                                    <button
                                        type="button"
                                        class="ml-2 px-2 py-1 text-sm font-medium text-indigo-600 bg-indigo-100 rounded hover:bg-indigo-200 focus:outline-none focus:ring focus:ring-indigo-300"
                                        @click="setItemToMax(item)"
                                    >
                                        {{ $t('credit-payment.all') }}
                                    </button>
                                </td>
                                <td class="py-2 w-60 text-right align-middle">
                                    <div class="flex flex-col items-end">
                                        <span class="py-1 text-sm text-gray-500">{{ item.max_quantity }}x</span>
                                        <input
                                            type="number"
                                            v-model="item.credit_quantity"
                                            class="w-20 text-center rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 sm:text-sm"
                                            :min="0"
                                            :max="item.max_quantity"
                                            placeholder="0"
                                            @input="updateItemCredits(item)"
                                        />
                                        <ValidationError :name="`items.${iIndex}.credit_quantity`" />
                                    </div>
                                </td>
                                <td class="py-2 text-right align-middle">
                                    <div class="flex flex-col items-end">
                                        <span class="py-1 text-sm text-gray-500">{{ formatPrice(item.max_subtotal) }}</span>
                                        <MoneyInput v-model="item.credit_subtotal" class="w-40 text-right" />
                                        <ValidationError :name="`items.${iIndex}.credit_subtotal`" />
                                    </div>
                                </td>
                                <td class="py-2 text-right align-middle">
                                    <div class="flex flex-col items-end">
                                        <span class="py-1 text-sm text-gray-500">{{ formatPrice(item.max_tax) }}</span>
                                        <MoneyInput v-model="item.credit_tax" class="w-40 text-right" />
                                        <ValidationError :name="`items.${iIndex}.credit_tax`" />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Fees -->
                    <div class="col-span-6" v-if="localOrder.fees.length > 0">
                        <h3 class="text-xl font-semibold mb-4">{{ $t('credit-payment.additional_fees') }}</h3>
                        <table class="w-full border-collapse">
                            <thead class="border-b">
                            <tr>
                                <th class="text-left py-2 align-middle">{{ $t('credit-payment.addition') }}</th>
                                <th class="text-left py-2 align-middle"></th>
                                <th class="text-right py-2 align-middle">{{ $t('credit-payment.subtotal') }}</th>
                                <th class="text-right py-2 align-middle">{{ $t('credit-payment.vat') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(fee, fIndex) in localOrder.fees" :key="fIndex" class="border-b">
                                <td class="py-2 pr-4 w-[500px] align-middle">
                                    {{ fee.name }}
                                    <button
                                        type="button"
                                        class="ml-2 px-2 py-1 text-sm font-medium text-indigo-600 bg-indigo-100 rounded hover:bg-indigo-200 focus:outline-none focus:ring focus:ring-indigo-300"
                                        @click="setFeeToMax(fee)"
                                    >
                                        {{ $t('credit-payment.all') }}
                                    </button>
                                </td>
                                <td class="py-2 w-60 text-right align-middle"></td>
                                <td class="py-2 text-right align-middle">
                                    <div class="flex flex-col items-end">
                                        <span class="py-1 text-sm text-gray-500">{{ formatPrice(fee.max_subtotal) }}</span>
                                        <MoneyInput v-model="fee.credit_subtotal" class="w-40 text-right" />
                                        <ValidationError :name="`fees.${fIndex}.credit_subtotal`" />
                                    </div>
                                </td>
                                <td class="py-2 text-right align-middle">
                                    <div class="flex flex-col items-end">
                                        <span class="py-1 text-sm text-gray-500">{{ formatPrice(fee.max_tax) }}</span>
                                        <MoneyInput v-model="fee.credit_tax" class="w-40 text-right" />
                                        <ValidationError :name="`fees.${fIndex}.credit_tax`" />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- Shipping -->
                    <div class="col-span-6">
                        <h3 class="text-xl font-semibold mb-4">{{ $t('credit-payment.shipping') }}</h3>
                        <table class="w-full border-collapse">
                            <thead class="border-b">
                            <tr>
                                <th class="text-left py-2 align-middle">{{ $t('credit-payment.shipping_method') }}</th>
                                <th class="text-left py-2 align-middle"></th>
                                <th class="text-right py-2 align-middle">{{ $t('credit-payment.subtotal') }}</th>
                                <th class="text-right py-2 align-middle">{{ $t('credit-payment.vat') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="border-b">
                                <td class="py-2 w-[500px] pr-4 align-middle">
                                    {{ localOrder.shipping_method.name }}
                                    <button
                                        type="button"
                                        class="ml-2 px-2 py-1 text-sm font-medium text-indigo-600 bg-indigo-100 rounded hover:bg-indigo-200 focus:outline-none focus:ring focus:ring-indigo-300"
                                        @click="setShippingToMax"
                                    >
                                        {{ $t('credit-payment.all') }}
                                    </button>
                                </td>
                                <td class="py-2 w-60 text-right align-middle"></td>
                                <td class="py-2 text-right align-middle">
                                    <div class="flex flex-col items-end">
                                        <span class="py-1 text-sm text-gray-500">{{ formatPrice(max_shipping_subtotal) }}</span>
                                        <MoneyInput v-model="localOrder.credit_shipping_subtotal" class="w-40 text-right" />
                                        <ValidationError name="credit_shipping_subtotal" />
                                    </div>
                                </td>
                                <td class="py-2 text-right align-middle">
                                    <div class="flex flex-col items-end">
                                        <span class="py-1 text-sm text-gray-500">{{ formatPrice(max_shipping_tax) }}</span>
                                        <MoneyInput v-model="localOrder.credit_shipping_tax" class="w-40 text-right" />
                                        <ValidationError name="credit_shipping_tax" />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </template>


                <div class="col-span-6 space-x-2 text-right">
                    <NavButton :href="route('orders.show', { order: order.id })">{{ $t('Back') }}</NavButton>
                    <Button type="submit" :disabled="form.processing">{{ $t('Update') }}</Button>
                </div>
            </div>
        </form>
    </AuthenticatedLayout>
</template>

<script setup>
import {reactive, ref, watch} from 'vue';
import { Head, useForm } from '@inertiajs/vue3'
import AuthenticatedLayout from "@/Layouts/Authenticated.vue";
import Button from '@/Components/Button.vue';
import Label from '@/Components/Label.vue';
import MoneyInput from '@/Components/MoneyInput.vue';
import NavButton from '@/Components/NavButton.vue';
import Textarea from '@/Components/Textarea.vue';
import ValidationError from '@/Components/ValidationError.vue';

const props = defineProps({
    order: Object,
    usedBankAccounts: Object,
    reasons: Array,
    otherReasonId: Number,
});

const rawBankAccounts = props.usedBankAccounts;
// eslint-disable-next-line vue/no-dupe-keys
const usedBankAccounts = Array.isArray(rawBankAccounts)
    ? rawBankAccounts
    : Object.values(rawBankAccounts);
const selectedBankAccount = ref(null);

function applySelectedBankAccount() {
    if (selectedBankAccount.value === "other") {
        // Clear fields for manual entry
        form.counterpart_name = '';
        form.iban = '';
        form.bic_code = '';
    } else if (selectedBankAccount.value) {
        const account = selectedBankAccount.value;
        form.counterpart_name = account.counterpart_name;
        form.iban = account.iban;
        form.bic_code = account.bic_code;
    }
}

function formatPrice(amount) {
    return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount);
}

const creditPayment = props.order.credit_payment || {};

const form = useForm({
    reason_id: creditPayment.reason_id || '',
    reason: creditPayment.reason || '',
    requires_accounting_sync: false,
    items: [],
    fees: [],
    credit_shipping_subtotal: creditPayment.credit_shipping_subtotal !== undefined && creditPayment.credit_shipping_subtotal !== null ? creditPayment.credit_shipping_subtotal / 100 : 0,
    credit_shipping_tax: creditPayment.credit_shipping_tax !== undefined && creditPayment.credit_shipping_tax !== null ? creditPayment.credit_shipping_tax / 100 : 0,
    to_much_paid_double_paid_amount: creditPayment.to_much_paid !== undefined && creditPayment.to_much_paid !== null ? creditPayment.to_much_paid / 100 : 0,
    counterpart_name: creditPayment.counterpart_name,
    iban: creditPayment.iban,
    bic_code: creditPayment.bic_code,
});

const max_shipping_subtotal = props.order.shipping_subtotal / 100;
const max_shipping_tax = props.order.shipping_tax / 100;

const localOrder = reactive({
    items: props.order.items.map(item => {
        const refundItem = item.refund_item || {};
        return {
            ...item,
            max_quantity: item.quantity,
            max_subtotal: item.total / 100,
            max_tax: item.total_tax / 100,
            credit_quantity: refundItem.refunded_quantity || 0,
            credit_subtotal: (refundItem.refunded_subtotal || 0) / 100,
            credit_tax: (refundItem.refunded_tax || 0) / 100,
        }
    }),
    fees: props.order.fees.map(fee => {
        const refundFee = creditPayment.refundFees?.find(r => r.order_item_id === fee.id) || {};
        return {
            ...fee,
            max_subtotal: fee.subtotal / 100,
            max_tax: fee.tax / 100,
            credit_subtotal: (refundFee.refunded_subtotal || 0) / 100,
            credit_tax: (refundFee.refunded_tax || 0) / 100,
        }
    }),
    credit_shipping_subtotal: form.credit_shipping_subtotal,
    credit_shipping_tax: form.credit_shipping_tax,
    shipping_method: props.order.shipping_method,
    shipping_subtotal: props.order.shipping_subtotal,
    shipping_tax: props.order.shipping_tax,
});

watch(() => form.reason_id, (newValue) => {
    if (newValue !== 5) {
        form.reason = '';
    }

    if (newValue === 4) { // shipping cost credit
        setShippingToMax();
        localOrder.items.forEach(item => resetItem(item));
        localOrder.fees.forEach(fee => resetFee(fee));
    } else if (newValue === 3 || newValue === 12) { // customer would like to cancel the order or golden ticket action
        setShippingToMax();
        localOrder.items.forEach(item => setItemToMax(item));
        localOrder.fees.forEach(fee => setFeeToMax(fee));
    } else {
        resetShipping();
        localOrder.items.forEach(item => resetItem(item));
        localOrder.fees.forEach(fee => resetFee(fee));
    }
});

function updateItemCredits(item) {
    const creditQty = Number(item.credit_quantity) || 0;
    if (item.max_quantity > 0) {
        const unit_subtotal = item.max_subtotal / item.max_quantity;
        const unit_tax = item.max_tax / item.max_quantity;
        item.credit_subtotal = unit_subtotal * creditQty;
        item.credit_tax = unit_tax * creditQty;
    }
}

function setItemToMax(item) {
    item.credit_quantity = item.max_quantity;
    item.credit_subtotal = item.max_subtotal;
    item.credit_tax = item.max_tax;
    updateItemCredits(item);
}

function setShippingToMax() {
    localOrder.credit_shipping_subtotal = max_shipping_subtotal;
    localOrder.credit_shipping_tax = max_shipping_tax;
}

function setFeeToMax(fee) {
    fee.credit_subtotal = fee.max_subtotal;
    fee.credit_tax = fee.max_tax;
}

function resetItem(item) {
    item.credit_quantity = 0;
    item.credit_subtotal = 0;
    item.credit_tax = 0;
    updateItemCredits(item);
}

function resetShipping() {
    localOrder.credit_shipping_subtotal = 0;
    localOrder.credit_shipping_tax = 0;
}

function resetFee(fee) {
    fee.credit_subtotal = 0;
    fee.credit_tax = 0;
}


function onSubmit() {
    form.items = localOrder.items.map(item => ({
        id: item.id,
        credit_quantity: item.credit_quantity,
        credit_subtotal: item.credit_subtotal,
        credit_tax: item.credit_tax,
    }));

    form.fees = localOrder.fees.map(fee => ({
        id: fee.id,
        credit_subtotal: fee.credit_subtotal,
        credit_tax: fee.credit_tax,
    }));

    form.credit_shipping_subtotal = localOrder.credit_shipping_subtotal;
    form.credit_shipping_tax = localOrder.credit_shipping_tax;

    form.post(route('credit-payment.update', { order: props.order.id, creditPayment: creditPayment.id }), {
        preserveScroll: true
    });
}
</script>
